<template>
  <div id="next-stop-settings">
    <div class="page-title px-4">
      <h2>
        <span class="me-2">Documentation</span>
      </h2>
    </div>
    <v-divider class="mt-4 mb-10"></v-divider>
    <div>
      <v-tabs v-model="currentTab" show-arrows>
        <v-tab v-for="tab in tabs" :key="tab.icon">
          <span>
            {{ tab.title }}
          </span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab">
        <v-tab-item>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <iframe style="border:0;width:100%;height:550px;" src="https://docs.google.com/document/d/e/2PACX-1vSlelUeAon1PUCKKyeWWOF2RSfppKb-5jPoP3tQmXCuh95uer74K4bLTEu6DzEwYxpL3EPR90rJxoBy/pub?embedded=true"></iframe>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <iframe style="border:0;width:100%;height:550px;" src="https://docs.google.com/document/d/e/2PACX-1vRbjQdKAdEkj9xkDY8OsXh5i1lE64nzz5P8uYqEbJUEQTM697iW_xd36jyG7_Rj4MIWICe7fsizx5PI/pub?embedded=true"></iframe>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api"
import { mapState } from "vuex"

import store from "@/store"

export default {
  name: "Documentation",
  metaInfo: {
    title: "Documentation",
  },
  components: { },
  data() {
    return {
      currentTab: "",
      tabs: [
        { title: "Changelog" },
        { title: "User Guide" },
      ],
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings,
    }),
  },
  created() {
    this.localSettings = JSON.parse(JSON.stringify(this.settings))
  },
  methods: {

  },
  setup() {
    const currentTab = ref(null)

    return {
      currentTab,
    }
  },
}
</script>
